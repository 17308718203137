import Vue from 'vue';
import VueMq from 'vue-mq';

export default () => {
    Vue.use(VueMq, {
        breakpoints: {
            xs: 992,
            md: Infinity,
        }
    });
};
