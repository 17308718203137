import Vue from 'vue'
import VueSocialAuth from 'vue-social-auth'

export default (obj, inject) => {
  // aviable only in client side
  if (!process.client) {
    return
  }

  //initialize plugin with options
  const pluginOptions = [{"property":"$socialAuth","providers":{"google":{"clientId":"403556770265-jf84ef86ocfr3gohit8l2p6eo29942a7.apps.googleusercontent.com","redirectUri":"https:\u002F\u002Fbookesse.com\u002Fsocial-login\u002Fgoogle\u002Fcallback"},"facebook":{"clientId":"483368919832567","redirectUri":"https:\u002F\u002Fbookesse.com\u002Fsocial-login\u002Ffacebook\u002Fcallback"}}}][0] || {}
  Vue.use(VueSocialAuth, pluginOptions)
}
